html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}

q::before,
q::after,
blockquote::before,
blockquote::after {
  content: "";
}

a img {
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

button {
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: inherit;
  width: auto;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@font-face {
  font-family: "Scope";
  font-weight: 300;
  src: url("../fonts/Scope-Light.otf") format("opentype");
}
/*
* See utils/grid.scss for how to use the grid system
*/
.section-p-text__container, .section-embed__container, .section-text__container, .section-hero__container, .section-list__container, .section-contact__container, .section-callout__container {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(8, minmax(5px, 1fr));
}
@media only screen and (max-width: 768px) {
  .section-p-text__container, .section-embed__container, .section-text__container, .section-hero__container, .section-list__container, .section-contact__container, .section-callout__container {
    grid-column-gap: 10px;
    grid-template-columns: repeat(4, minmax(5px, 1fr));
    margin: 0 10px;
  }
}

.section-grid__grid-wrapper {
  align-items: stretch;
  display: grid;
  grid-column-gap: 0;
  grid-template-columns: repeat(9, minmax(5px, 1fr));
}
@media only screen and (max-width: 768px) {
  .section-grid__grid-wrapper {
    grid-column-gap: 0;
    grid-template-columns: repeat(1, minmax(5px, 1fr));
    margin: 0;
    width: 100% !important;
  }
}

.section-home-carousel__title, .section-contact__headline {
  font-family: "Scope", sans-serif;
  font-size: 60px;
  line-height: 1.083;
}
@media only screen and (max-width: 768px) {
  .section-home-carousel__title, .section-contact__headline {
    font-size: 40px;
  }
}

.section-hero__headline, .section-callout__description {
  font-family: "Scope", sans-serif;
  font-size: 40px;
  line-height: 1.25;
}
@media only screen and (max-width: 768px) {
  .section-hero__headline, .section-callout__description {
    font-size: 28px;
    line-height: 1.32;
  }
}

.section-grid__tile-title {
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-size: 45px;
  font-weight: 500;
  line-height: 1.04;
}

.section-home-carousel__eyebrow, .section-callout__cta, body, .section-home-carousel__link-label span, .section-grid__tile-cta, .section-p-text__headline, .section-embed__embedcode, .section-text__accordion-button, .section-text__headline, .section-news-carousel__item-headline, .section-news-carousel__headline, .section-list__headline, .section-callout__headline {
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-size: 24px;
  line-height: 1.35;
}
@media only screen and (max-width: 768px) {
  .section-home-carousel__eyebrow, .section-callout__cta, body, .section-home-carousel__link-label span, .section-grid__tile-cta, .section-p-text__headline, .section-embed__embedcode, .section-text__accordion-button, .section-text__headline, .section-news-carousel__item-headline, .section-news-carousel__headline, .section-list__headline, .section-callout__headline {
    font-size: 18px;
    line-height: 1.32;
  }
}

.section-p-text__headline, .section-embed__embedcode, .section-text__accordion-button, .section-text__headline, .section-news-carousel__item-headline, .section-news-carousel__headline, .section-list__headline, .section-callout__headline {
  font-weight: 500;
}

.section-home-carousel__link-label span, .section-grid__tile-cta {
  font-style: italic;
}

.section-p-text__hover-content, .section-news-carousel__item-cta, .section-news-carousel__item-description, .section-list__hover-content, .section-grid__scroll, .section-grid__tile-description {
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-size: 20px;
  line-height: 1.35;
}
@media only screen and (max-width: 768px) {
  .section-p-text__hover-content, .section-news-carousel__item-cta, .section-news-carousel__item-description, .section-list__hover-content, .section-grid__scroll, .section-grid__tile-description {
    font-size: 18px;
    line-height: 1.32;
  }
}

.section-p-text__list-item, .section-list__list-item {
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-size: 20px;
  line-height: 28px;
}
@media only screen and (max-width: 768px) {
  .section-p-text__list-item, .section-list__list-item {
    font-size: 15px;
    line-height: 18px;
  }
}

.section-home-carousel__caption, .section-grid__tile-index, .section-gallery__caption {
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-size: 19px;
}

.navigation__nav-item {
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.025em;
  line-height: 20px;
}
@media only screen and (max-width: 768px) {
  .navigation__nav-item {
    font-size: 50px;
    line-height: 86px;
  }
}

.footer {
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.section-p-text__description a, .section-text__accordion-content-inner a, .section-text__description a, .section-list__description a, .section-contact__content a, .section-callout__cta a {
  color: inherit;
  font-style: italic;
  text-decoration: none;
}
.section-p-text__description a:hover, .section-text__accordion-content-inner a:hover, .section-text__description a:hover, .section-list__description a:hover, .section-contact__content a:hover, .section-callout__cta a:hover {
  font-style: normal;
}
.section-p-text__description strong, .section-text__accordion-content-inner strong, .section-text__description strong, .section-list__description strong, .section-contact__content strong, .section-callout__cta strong {
  font-weight: 500;
}
.section-p-text__description em, .section-text__accordion-content-inner em, .section-text__description em, .section-list__description em, .section-contact__content em, .section-callout__cta em {
  font-style: italic;
}
.section-p-text__description p:not(:last-child), .section-text__accordion-content-inner p:not(:last-child), .section-text__description p:not(:last-child), .section-list__description p:not(:last-child), .section-contact__content p:not(:last-child), .section-callout__cta p:not(:last-child) {
  margin-bottom: 20px;
}
.section-p-text__description ul, .section-text__accordion-content-inner ul, .section-text__description ul, .section-list__description ul, .section-contact__content ul, .section-callout__cta ul {
  list-style: none;
  margin-left: 0;
  padding-left: 20px;
  text-indent: -20px;
}
.section-p-text__description ul:not(:last-child), .section-text__accordion-content-inner ul:not(:last-child), .section-text__description ul:not(:last-child), .section-list__description ul:not(:last-child), .section-contact__content ul:not(:last-child), .section-callout__cta ul:not(:last-child) {
  margin-bottom: 20px;
}
.section-p-text__description ul li, .section-text__accordion-content-inner ul li, .section-text__description ul li, .section-list__description ul li, .section-contact__content ul li, .section-callout__cta ul li {
  margin-bottom: 10px;
}
.section-p-text__description ul li::before, .section-text__accordion-content-inner ul li::before, .section-text__description ul li::before, .section-list__description ul li::before, .section-contact__content ul li::before, .section-callout__cta ul li::before {
  content: "/";
  padding-right: 10px;
  width: 10px;
}

.section-news-carousel__container, .section-home-carousel__content, .section-gallery::before, .navigation__container, .interactive-line, .footer__container, .section-p-text__container, .section-embed__container, .section-text__container, .section-hero__container, .section-list__container, .section-contact__container, .section-callout__container, .section-grid__grid-wrapper {
  margin: 0 auto;
  width: calc(100% - 60px);
}
@media only screen and (max-width: 768px) {
  .section-news-carousel__container, .section-home-carousel__content, .section-gallery::before, .navigation__container, .interactive-line, .footer__container, .section-p-text__container, .section-embed__container, .section-text__container, .section-hero__container, .section-list__container, .section-contact__container, .section-callout__container, .section-grid__grid-wrapper {
    width: calc(100% - 20px);
  }
}

:focus {
  outline: 1px solid #000;
}

.footer {
  position: relative;
}
.footer__container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .footer__container {
    padding: 20px 0;
  }
}
.footer__left-block {
  display: flex;
  position: relative;
}
.footer__left-block a {
  color: inherit;
  font-style: italic;
  margin-left: 25px;
  text-decoration: none;
}
.footer__left-block a:hover {
  font-style: normal;
}
.footer__nav {
  display: flex;
}
.footer__nav-item {
  color: #606060;
  position: relative;
}
.footer__nav-item:not(:last-child) {
  margin-right: 15px;
}
.footer__nav-item a {
  color: inherit;
  font-style: italic;
  text-decoration: none;
}
.footer__nav-item a:hover {
  font-style: normal;
}

[data-glitch-container] {
  overflow: hidden;
}
[data-glitch-container] [data-glitch] {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 10;
}
[data-glitch-container] img {
  width: 100%;
}

.interactive-line {
  background-color: #000;
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
@media only screen and (max-width: 768px) {
  .interactive-line {
    width: 100%;
  }
}
.interactive-line.loaded {
  background-color: transparent;
}
.interactive-line--top {
  top: 0;
}
.interactive-line--bottom {
  bottom: 0;
}
.interactive-line canvas {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 768px) {
  .interactive-line canvas {
    pointer-events: none;
  }
}

.lazyload {
  opacity: 0;
  visibility: hidden;
}
.lazyload.loaded {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  visibility: visible;
}

.navigation {
  background-color: #fff;
  position: fixed;
  top: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: 12;
}
@media only screen and (max-width: 768px) {
  .navigation .interactive-line {
    width: 100%;
  }
}
.is-scrolled-down .navigation {
  transform: translateY(-100%);
}
@media only screen and (max-width: 768px) {
  .is-scrolled-down .navigation.navigation--active {
    transform: initial;
  }
}
.navigation__container {
  align-items: center;
  color: #000;
  display: flex;
  height: 58px;
  justify-content: space-between;
}
.navigation__logo {
  position: relative;
  width: 190px;
  z-index: 2;
}
.navigation__logo svg {
  fill: inherit;
  height: auto;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .navigation__nav {
    background: #fff;
    border-top: 1px solid #000;
    display: none;
    height: calc(100vh - 58px);
    left: 0;
    position: fixed;
    top: 58px;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .navigation__nav--active {
    display: block;
  }
}
.navigation__nav-item {
  display: inline;
}
@media only screen and (max-width: 768px) {
  .navigation__nav-item {
    border-bottom: 1px solid #000;
    display: block;
    position: relative;
  }
}
.navigation__nav-item--active {
  font-style: normal;
  font-weight: 500;
}
.navigation__nav-item:hover {
  font-style: normal;
}
.navigation__nav-item:hover::after {
  font-style: normal;
}
.navigation__nav-item:not(:last-child)::after {
  content: "/";
  font-style: normal;
  font-weight: normal !important;
  padding-left: 10px;
}
@media only screen and (max-width: 768px) {
  .navigation__nav-item:not(:last-child)::after {
    display: none;
  }
}
.navigation__nav-item a {
  color: inherit;
  position: relative;
  text-decoration: none;
  z-index: 2;
}
@media only screen and (max-width: 768px) {
  .navigation__nav-item a {
    display: block;
    left: 0;
    padding: 0 30px;
  }
  .navigation__nav-item a::before {
    font-style: normal;
    font-weight: normal !important;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 768px) and (max-width: 768px) {
  .navigation__nav-item a::before {
    content: "/";
  }
}
@media only screen and (min-width: 769px) {
  .navigation__toggler {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .navigation--active {
    background-color: #fff;
  }
  .navigation--active .interactive-line {
    display: none;
  }
}

.section-callout {
  position: relative;
}
.section-callout__container {
  padding-bottom: 90px;
}
@media only screen and (max-width: 768px) {
  .section-callout__container {
    padding-bottom: 60px;
  }
}
.section-callout__headline {
  grid-column-end: span 5;
  grid-column-start: 1;
  margin-bottom: 60px;
  margin-top: 20px;
}
@media only screen and (max-width: 768px) {
  .section-callout__headline {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-callout__headline {
    grid-column-start: 1;
  }
}
.section-callout__description {
  grid-column-end: span 5;
  grid-column-start: 1;
  margin-top: 60px;
}
@media only screen and (max-width: 768px) {
  .section-callout__description {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-callout__description {
    grid-column-start: 1;
  }
}
.section-callout__cta {
  grid-column-end: span 5;
  grid-column-start: 1;
  color: #000;
  margin-top: 34px;
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .section-callout__cta {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-callout__cta {
    grid-column-start: 1;
  }
}

.section-contact {
  position: relative;
}
.section-contact__container {
  padding: 34px 0 56px;
}
@media only screen and (max-width: 768px) {
  .section-contact__container {
    padding: 150px 0 75px;
  }
}
.section-contact__headline {
  grid-column-end: span 5;
}
@media only screen and (max-width: 768px) {
  .section-contact__headline {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 1024px) {
  .section-contact__headline {
    margin-bottom: 70px;
  }
}
.section-contact__content {
  grid-column-end: span 4;
  grid-column-start: 1;
  align-self: end;
}
@media only screen and (max-width: 768px) {
  .section-contact__content {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-contact__content {
    grid-column-start: 1;
  }
}
.section-contact__image {
  grid-column-end: span 4;
  grid-column-start: 5;
  margin-top: -10%;
}
@media only screen and (max-width: 768px) {
  .section-contact__image {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-contact__image {
    grid-column-start: 1;
  }
}
@media only screen and (max-width: 768px) {
  .section-contact__image {
    margin-top: 40px;
  }
}
.section-contact__image img {
  width: 100%;
}

.section-gallery {
  padding-top: 0px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .section-gallery {
    padding-top: 70px;
  }
}
.section-gallery::before {
  content: "";
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}
.section-gallery__gallery-wrapper {
  overflow: hidden;
  width: 100vw;
}
.section-gallery__gallery-slide {
  position: relative;
  width: 33.33vw;
}
.section-gallery__gallery-slide img,
.section-gallery__gallery-slide video {
  display: block;
  width: 100%;
}
.section-gallery__gallery-slide video.tns-lazy-img.loading {
  opacity: 1;
}
.section-gallery__caption {
  bottom: 15px;
  color: #fff;
  left: 15px;
  max-width: 390px;
  position: absolute;
}
.section-gallery__controls {
  padding: 0;
  text-align: center;
}
.section-gallery__button {
  cursor: pointer;
  height: 69px;
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 1025px) {
  .section-gallery__button {
    position: absolute;
    top: 50%;
  }
  .section-gallery__button:first-child {
    left: 20px;
  }
  .section-gallery__button:last-child {
    right: 20px;
  }
}
.section-gallery__button svg {
  fill: #000;
  transition: transform 0.15s ease-in-out;
}
.section-gallery__button:hover:first-child svg {
  transform: translateX(-10px);
}
.section-gallery__button:hover:last-child svg {
  transform: translateX(10px);
}
.section-gallery__button:not(:last-child) {
  margin-right: 20px;
}

.section-grid__grid-wrapper {
  position: relative;
}
.section-grid__grid-wrapper .interactive-line--grid-1 {
  grid-column-start: 4;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 1px;
  z-index: 2;
}
@media only screen and (max-width: 768px) {
  .section-grid__grid-wrapper .interactive-line--grid-1 {
    grid-column-start: 1;
  }
}
@media only screen and (max-width: 768px) {
  .section-grid__grid-wrapper .interactive-line--grid-1 {
    display: none;
  }
}
.section-grid__grid-wrapper .interactive-line--grid-2 {
  grid-column-start: 7;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 1px;
  z-index: 2;
}
@media only screen and (max-width: 768px) {
  .section-grid__grid-wrapper .interactive-line--grid-2 {
    grid-column-start: 1;
  }
}
@media only screen and (max-width: 768px) {
  .section-grid__grid-wrapper .interactive-line--grid-2 {
    display: none;
  }
}
.section-grid__grid-wrapper--square .section-grid__tile {
  padding: 50%;
}
.section-grid__grid-wrapper--square .section-grid__tile.section-grid__tile--double {
  padding: 25%;
}
@media only screen and (max-width: 768px) {
  .section-grid__grid-wrapper--square .section-grid__tile.section-grid__tile--double {
    padding: 50%;
  }
}
.section-grid__tile {
  grid-column-end: span 3;
  height: 0;
  padding-bottom: 152%;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .section-grid__tile {
    grid-column-end: span 4;
  }
}
.section-grid__tile .interactive-line {
  width: 100%;
  z-index: 4;
}
.section-grid__tile.is-scrollable .section-grid__scroll {
  display: flex;
}
.section-grid__tile.is-scrollable.is-scrolled .section-grid__scroll {
  opacity: 0;
}
.section-grid__tile--double {
  padding-bottom: 76%;
  grid-column-end: span 6;
}
@media only screen and (max-width: 768px) {
  .section-grid__tile--double {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-grid__tile--content {
    height: auto;
    padding: initial !important;
  }
  .section-grid__tile--content .section-grid__tile-content-wrapper {
    padding: 30px 15px;
    position: relative;
  }
}
.section-grid__tile-content-wrapper {
  box-sizing: border-box;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding: 20px 25px;
  position: absolute;
  top: 0;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .section-grid__tile-content-wrapper {
    padding: 10px 15px;
  }
}
.section-grid__tile-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.section-grid__tile-image img,
.section-grid__tile-image video {
  display: block;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.section-grid__tile-index {
  display: block;
  margin-bottom: 30px;
}
.section-grid__tile-title {
  margin-bottom: 42px;
}
.section-grid__tile-cta {
  color: #000;
  display: inline-block;
  margin-top: 30px;
  text-decoration: none;
}
.section-grid__tile-cta:hover {
  font-style: normal;
}
.section-grid__scroll {
  align-items: flex-end;
  background: linear-gradient(180.58deg, rgba(255, 255, 255, 0.55) 0.5%, #fff 80.94%);
  bottom: 0;
  display: none;
  height: 75px;
  justify-content: flex-end;
  padding: 10px 0;
  position: absolute;
  right: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
}

.section-home-carousel {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.section-home-carousel__background {
  height: 100%;
  position: relative;
  width: 100%;
}
.section-home-carousel__background::after {
  background-color: rgba(0, 0, 0, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.section-home-carousel__background img {
  height: 100%;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 1s ease-in-out;
  width: 100%;
}
.section-home-carousel__background > div.active img {
  opacity: 1;
}
.section-home-carousel__background > div.active .section-home-carousel__caption {
  opacity: 1;
}
.section-home-carousel__caption {
  bottom: 10px;
  color: #fff;
  left: 10px;
  opacity: 0;
  position: absolute;
  transition: opacity 1s ease-in-out;
}
.section-home-carousel__content {
  bottom: 84px;
  color: #fff;
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  left: 75px;
  opacity: 1;
  position: absolute;
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;
  visibility: visible;
  width: 62.5%;
  z-index: 11;
}
@media only screen and (max-width: 768px) {
  .section-home-carousel__content {
    bottom: 92px;
    justify-content: flex-start;
    left: 20px;
    text-align: left;
    width: calc(100% - 40px);
  }
}
.section-home-carousel__eyebrow {
  margin-bottom: 20px;
  position: relative;
  height: 50px;
}
@media only screen and (max-width: 768px) {
  .section-home-carousel__eyebrow {
    flex-basis: 100%;
  }
}
.section-home-carousel__eyebrow p {
  opacity: 0;
  position: absolute;
  transition: opacity ease-in-out 0.8s;
}
.section-home-carousel__eyebrow p.active {
  opacity: 1;
  transition-delay: 0.6s;
}
.section-home-carousel__title {
  max-width: 900px;
  position: relative;
}
.section-home-carousel__title div {
  opacity: 0;
  position: absolute;
  transition: opacity ease-in-out 0.8s;
}
.section-home-carousel__title div.active {
  opacity: 1;
  transition-delay: 0.6s;
}
@media only screen and (max-width: 768px) {
  .section-home-carousel__title {
    flex-basis: 100%;
    max-width: 330px;
  }
}
.section-home-carousel__link-label {
  max-width: 900px;
  position: relative;
  color: inherit;
  height: 66px;
  width: auto;
}
.section-home-carousel__link-label:hover span {
  font-style: normal !important;
}
.section-home-carousel__link-label span {
  opacity: 0;
  display: inline-block;
  margin-top: 34px;
  text-decoration: none;
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-size: 24px;
  line-height: 1.35;
  position: absolute;
  transition: opacity ease-in-out 0.8s;
}
.section-home-carousel__link-label span.active {
  opacity: 1;
  transition-delay: 0.6s;
}
.section-home-carousel__illustrations {
  height: 100%;
  left: 0;
  position: absolute;
  top: 58px;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .section-home-carousel__illustrations {
    display: none;
  }
}
.section-home-carousel__illustrations-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.section-home-carousel__illustrations-wrapper img,
.section-home-carousel__illustrations-wrapper img.lazyload {
  opacity: 0;
  position: absolute;
  transition: opacity ease-in-out 0.8s;
  width: auto;
}
.section-home-carousel__illustrations-wrapper.active img {
  opacity: 1;
}
.section-home-carousel__illustrations-wrapper.active .line-horizontal {
  transform: scaleX(1);
}
.section-home-carousel__illustrations-wrapper.active .line-vertical {
  transform: scaleY(1);
}
.section-home-carousel__hidden-links {
  display: none;
}
.section-home-carousel .line-horizontal {
  background-color: #fff;
  height: 1px;
  position: absolute;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 1s ease-in-out;
  width: 30%;
}
.section-home-carousel .line-vertical {
  background-color: #fff;
  height: 40%;
  position: absolute;
  transform: scaleY(0);
  transform-origin: 50% 0;
  transition: transform 1s ease-in-out;
  width: 1px;
}
.section-home-carousel__controls {
  bottom: 40px;
  position: absolute;
  right: 40px;
  z-index: 11;
}
@media only screen and (max-width: 768px) {
  .section-home-carousel__controls {
    bottom: 8px;
    display: flex;
    justify-content: space-between;
    right: 20px;
    width: calc(100% - 40px);
  }
}
.section-home-carousel__controls svg {
  fill: #fff;
}
.section-home-carousel__button {
  cursor: pointer;
  padding: 35px;
}
@media only screen and (max-width: 768px) {
  .section-home-carousel__button {
    padding: 20px 0;
  }
}
.section-home-carousel__button svg {
  transition: transform 0.15s ease-in-out;
}
.section-home-carousel__button:hover:first-child svg {
  transform: translateX(-10px);
}
.section-home-carousel__button:hover:last-child svg {
  transform: translateX(10px);
}
.section-home-carousel__button:not(:last-child) {
  margin-right: 20px;
}
.section-home-carousel:not(.loaded) .section-home-carousel__content {
  opacity: 0;
  transition: none;
  visibility: hidden;
}

.section-list {
  position: relative;
}
.section-list__container {
  padding: 60px 0 60px;
}
@media only screen and (max-width: 768px) {
  .section-list__container {
    padding-bottom: 120px;
  }
}
.section-list__headline {
  grid-column-end: span 3;
}
@media only screen and (max-width: 768px) {
  .section-list__headline {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-list__headline {
    margin-bottom: 75px;
  }
}
.section-list__description {
  grid-column-end: span 5;
  grid-column-start: 4;
}
@media only screen and (max-width: 768px) {
  .section-list__description {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-list__description {
    grid-column-start: 1;
  }
}
.section-list__list {
  grid-column-end: span 5;
  grid-column-start: 4;
  grid-row-start: 2;
  margin-top: 100px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .section-list__list {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-list__list {
    grid-column-start: 1;
  }
}
@media only screen and (max-width: 768px) {
  .section-list__list {
    grid-row-start: initial;
    margin-top: 75px;
  }
}
@media only screen and (min-width: 769px) {
  .section-list__list.active .section-list__list-item:not(:hover) {
    border-top-color: currentColor;
    color: rgba(0, 0, 0, 0.2);
  }
}
@media only screen and (min-width: 769px) {
  .section-list__list.active .section-list__list-item:hover + * {
    border-top-color: #000;
  }
}
.section-list__list-item {
  border-top: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  padding: 13px 0;
  position: relative;
  transition: color 0.15s ease-in-out, border-top-color 0.15s ease-in-out;
  color: black;
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .section-list__list-item {
    border-top: 0;
    padding: 24px 0;
  }
  .section-list__list-item::before {
    background-color: #000;
    content: "";
    display: block;
    height: 1px;
    left: -10px;
    position: absolute;
    top: -1px;
    width: calc(100% + 20px);
  }
}
.section-list__list-item:last-child {
  border-bottom: 1px solid #000;
}
@media only screen and (max-width: 768px) {
  .section-list__list-item:last-child {
    border-bottom: 0;
  }
  .section-list__list-item:last-child::after {
    background-color: #000;
    bottom: -1px;
    content: "";
    display: block;
    height: 1px;
    left: -10px;
    position: absolute;
    width: calc(100% + 20px);
  }
}
.section-list__list-item span:first-child {
  flex: 0 0 40%;
  font-weight: 500;
}
@media only screen and (max-width: 768px) {
  .section-list__list-item span:first-child {
    flex-basis: 100%;
  }
}
.section-list__list-item span:last-child {
  flex: 0 0 60%;
}
@media only screen and (max-width: 768px) {
  .section-list__list-item span:last-child {
    flex-basis: 100%;
    margin-top: 6px;
  }
}
.section-list__list-link:hover {
  text-decoration: underline;
}
.section-list__hover-content {
  grid-column-end: span 3;
  grid-column-start: 1;
  grid-row-start: 2;
  margin: 100px auto 0;
  position: relative;
  text-align: center;
  width: 66.6666666667%;
}
@media only screen and (max-width: 768px) {
  .section-list__hover-content {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-list__hover-content {
    grid-column-start: 1;
  }
}
.section-list__hover-content--big {
  grid-column-end: span 4;
}
@media only screen and (max-width: 768px) {
  .section-list__hover-content--big {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-list__hover-content {
    display: none;
  }
}
.section-list__hover-content .section-list__hover-image {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease-in-out;
}
.section-list__hover-content .section-list__hover-image img,
.section-list__hover-content .section-list__hover-image video {
  width: 100%;
}
.section-list__hover-content .section-list__hover-image.active {
  opacity: 1;
}
.section-list__hover-content-inner {
  position: sticky;
  top: 50%;
}

.section-news-carousel {
  position: relative;
}
.section-news-carousel__container {
  padding: 22px 0 108px;
}
.section-news-carousel__headline {
  position: relative;
}
.section-news-carousel__slider-wrapper {
  align-items: baseline;
  display: flex;
  margin-bottom: 110px;
}
.section-news-carousel__item {
  position: relative;
  width: 408px;
}
.section-news-carousel__item::after {
  background-color: #000;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 30px;
  top: 0;
  width: 1px;
}
@media only screen and (max-width: 768px) {
  .section-news-carousel__item::after {
    right: 10px;
  }
}
.section-news-carousel__item-image {
  position: relative;
  width: 100%;
}
.section-news-carousel__item-image img {
  display: block;
  width: 100%;
}
.section-news-carousel__item-headline {
  margin-bottom: 24px;
  margin-top: 30px;
}
.section-news-carousel__item-description {
  margin-bottom: 20px;
}
.section-news-carousel__item-cta {
  color: #000;
  font-style: italic;
  text-decoration: none;
}

.section-hero {
  margin-top: 58px;
}
.section-hero__container {
  padding: 100px 0 180px;
}
.section-hero__content {
  grid-column-end: span 5;
  grid-column-start: 4;
}
@media only screen and (max-width: 768px) {
  .section-hero__content {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-hero__content {
    grid-column-start: 1;
  }
}
.section-hero__description {
  margin-top: 54px;
}

.section-text {
  position: relative;
}
.section-text__container {
  padding: 22px 0 250px;
}
.section-text__headline {
  grid-column-end: span 3;
}
@media only screen and (max-width: 768px) {
  .section-text__headline {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-text__headline {
    margin-bottom: 75px;
  }
}
.section-text__description {
  grid-column-end: span 5;
  grid-column-start: 4;
}
@media only screen and (max-width: 768px) {
  .section-text__description {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-text__description {
    grid-column-start: 1;
  }
}
.section-text__accordions {
  grid-column-end: span 5;
  grid-column-start: 4;
  margin-top: 100px;
}
@media only screen and (max-width: 768px) {
  .section-text__accordions {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-text__accordions {
    grid-column-start: 1;
  }
}
.section-text__accordion {
  border-top: 1px solid #000;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .section-text__accordion {
    border-top: 0;
  }
  .section-text__accordion::before {
    background-color: #000;
    content: "";
    display: block;
    height: 1px;
    left: -10px;
    position: absolute;
    top: -1px;
    width: calc(100% + 20px);
  }
}
.section-text__accordion:last-child {
  border-bottom: 1px solid #000;
}
@media only screen and (max-width: 768px) {
  .section-text__accordion:last-child {
    border-bottom: 0;
  }
  .section-text__accordion:last-child::after {
    background-color: #000;
    bottom: -1px;
    content: "";
    display: block;
    height: 1px;
    left: -10px;
    position: absolute;
    width: calc(100% + 20px);
  }
}
.section-text__accordion:not(.is-expanded) .section-text__accordion-content {
  max-height: 0 !important;
  padding-bottom: 0;
}
.section-text__accordion:not(.is-expanded) .section-text__accordion-content a {
  transition: visibility 1ms 1s;
  visibility: hidden;
}
.section-text__accordion.is-expanded .section-text__accordion-icon {
  transform: rotate(45deg);
}
.section-text__accordion-content {
  overflow: hidden;
  transition: max-height 1s ease;
}
.section-text__accordion-content-inner {
  padding-bottom: 100px;
}
.section-text__accordion-icon {
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.section-text__accordion-button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  width: 100%;
}
.section-text__list-title {
  margin-bottom: 24px;
  margin-top: 74px;
}
.navigation + .section-text {
  padding-top: 58px;
}
.navigation + .section-text .interactive-line {
  display: none;
}

.section-embed {
  position: relative;
}
.section-embed__container {
  padding: 0px 0 0px;
}
.section-embed__embedcode {
  grid-column-end: span 8;
  grid-column-start: 1;
  margin-top: 0px;
  width: 100%;
  height: 53.5vw;
}
@media only screen and (max-width: 768px) {
  .section-embed__embedcode {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-embed__embedcode {
    grid-column-start: 1;
  }
}
.section-embed__embedcode iframe {
  width: 100%;
  height: 100%;
}

.section-p-text {
  position: relative;
}
.section-p-text__container {
  padding: 22px 0 250px;
}
@media only screen and (max-width: 768px) {
  .section-p-text__container {
    padding-bottom: 120px;
  }
}
.section-p-text__headline {
  grid-column-end: span 3;
}
@media only screen and (max-width: 768px) {
  .section-p-text__headline {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-p-text__headline {
    margin-bottom: 75px;
  }
}
.section-p-text__description {
  grid-column-end: span 5;
  grid-column-start: 4;
}
@media only screen and (max-width: 768px) {
  .section-p-text__description {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-p-text__description {
    grid-column-start: 1;
  }
}
.section-p-text__list {
  grid-column-end: span 5;
  grid-column-start: 4;
  grid-row-start: 2;
  margin-top: 100px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .section-p-text__list {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-p-text__list {
    grid-column-start: 1;
  }
}
@media only screen and (max-width: 768px) {
  .section-p-text__list {
    grid-row-start: initial;
    margin-top: 75px;
  }
}
.section-p-text__list-item {
  display: flex;
  flex-wrap: wrap;
  padding: 13px 0;
  position: relative;
  color: black;
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .section-p-text__list-item {
    border-top: 0;
    padding: 24px 0;
  }
  .section-p-text__list-item::before {
    background-color: #000;
    content: "";
    display: block;
    height: 1px;
    left: -10px;
    position: absolute;
    top: -1px;
    width: calc(100% + 20px);
  }
}
.section-p-text__list-item span:first-child {
  flex: 0 0 40%;
  font-weight: 500;
}
@media only screen and (max-width: 768px) {
  .section-p-text__list-item span:first-child {
    flex-basis: 100%;
  }
}
.section-p-text__list-item span:last-child {
  flex: 0 0 60%;
}
@media only screen and (max-width: 768px) {
  .section-p-text__list-item span:last-child {
    flex-basis: 100%;
    margin-top: 6px;
  }
}
.section-p-text__list-link:hover {
  text-decoration: underline;
}
.section-p-text__hover-content {
  grid-column-end: span 3;
  grid-column-start: 1;
  grid-row-start: 2;
  margin: 100px auto 0;
  position: relative;
  text-align: center;
  width: 66.6666666667%;
}
@media only screen and (max-width: 768px) {
  .section-p-text__hover-content {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-p-text__hover-content {
    grid-column-start: 1;
  }
}
.section-p-text__hover-content--big {
  grid-column-end: span 4;
}
@media only screen and (max-width: 768px) {
  .section-p-text__hover-content--big {
    grid-column-end: span 4;
  }
}
@media only screen and (max-width: 768px) {
  .section-p-text__hover-content {
    display: none;
  }
}
.section-p-text__hover-content .section-p-text__hover-image {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease-in-out;
}
.section-p-text__hover-content .section-p-text__hover-image img,
.section-p-text__hover-content .section-p-text__hover-image video {
  width: 100%;
}
.section-p-text__hover-content .section-p-text__hover-image.active {
  opacity: 1;
}
.section-p-text__hover-content-inner {
  position: sticky;
  top: 50%;
}

@media only screen and (min-width: 769px) {
  .page--home .navigation {
    position: absolute;
  }
}
.page--home .navigation .interactive-line:not(.loaded) {
  background-color: #fff;
}
.page--home .navigation:not(.navigation--active) {
  background-color: transparent;
}
.page--home .navigation:not(.navigation--active) .navigation__container {
  border-bottom-color: #fff;
  color: #fff;
  fill: #fff;
}